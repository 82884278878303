.permissions_roles-container {
    margin-top: 9rem;
    margin-left: auto;
    margin-right: auto;
    width: 95%;
}

.permission_block {
    width: 100%;
    min-height: 5.4rem;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
    margin-top: .9rem;
    margin-bottom: .9rem;
    cursor: pointer;
}

.permission_block h6 {
    border-right: 1px solid gray;
    max-width: 18rem;
    line-height: 5.4rem;
    font-size: 2rem;
    font-weight: bold;
}

.permission-list {
    float: left;
    margin-top: -5.4rem;
    margin-left: 19.8rem;
    overflow: hidden;
} 

.permission-dialog-footer-container {
    display: flex;
    gap: 10px;
}

.permission-dialog-cancel {
    background-color: #d22e37;
}