.searching-input-container {
    display: flex;
    flex-direction: row;
    height: 3rem;
}

.searching-input-container__btn-search {
    background: none;
    border-bottom: 1px #9e9e9e solid;
    color: black;
    width: 3rem;
    height: 3rem;
    box-shadow: none;
    display: flex;
    justify-content: center;
    align-content: center;
    margin-left: 0 !important;
}

.searching-input-container__btn-search:hover {
    box-shadow: none;
    margin-left: 0 !important;
    color: #fff;
}

.searching-input-container__input {
    height: calc(3rem - 1px) !important;
    margin: 0 !important;
    margin-right: 0 !important;
}

.searching-input-container__input:hover {
    box-shadow: none !important;
    margin-right: 0 !important;
}

.searching-input-container__input:focus {
    box-shadow: none !important;
    margin-right: 0 !important;
}