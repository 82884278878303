.preview-file-dialog__container {
    position: relative;
}

.preview-file-dialog__menu {
    position: fixed !important;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%);
    text-align: center;
    background-color: white;
    box-shadow: 0px 2px 7px 1px rgba(0,0,0,0.75);
    border-radius: 3px;
    padding: 1rem;
    z-index: 1000;
}

.preview-file-dialog__menu.image {
    min-width: 20rem;
    min-height: 20rem;
    z-index: 1000;
}

.preview-file-dialog__menu.file {
    min-width: 20rem;
    min-height: 8rem;
}

.previewFileDialog__image {
    max-width: 100%;
    max-height: 100%;
}

.previewFileDialog__image img {
    max-width: 90%;
    min-height: 5rem;
    max-height: 13.5rem;
    box-shadow: 0px 0px 2px 2px rgba(0,0,0,0.1);
}

.previewFileDialog__file-name {
    font-size: medium;
    font-weight: bold;
}

.previewFileDialog__footer {
    display: flex;
    justify-content: space-between;
}
