.transfer-invite-dialog__toggle {
    background: none; 
    border: none; 
    box-shadow: none;
    padding: 0;
    margin: 0;
    text-transform: none;
    color: #039be5;
    font-size: 1.8rem;
    height: auto !important;
    padding: 0 !important;
    line-height: 1.8rem !important;
    display: flex;
    letter-spacing: normal;
}

.transfer-invite-dialog__toggle:hover {
    background: none; 
    border: none; 
    box-shadow: none;
}

.transfer-invite-dialog__toggle:focus {
    background: none; 
}

.transfer-invite-dialog__menu {
    border: 1px solid;
    width: 48rem;
    height: 45rem;
    transform: translate3D(0rem, -46rem, 0) !important;
    text-align: center;
    background-color: rgb(255, 255, 255);
    box-shadow: 0px 2px 7px 1px rgba(0,0,0,0.75);
    border-radius: 5px;
    z-index: 10;
}

.transfer-invite-dialog__search {
    margin: 0 0.4rem;
    margin-top: 0.2rem;
}

.transfer-invite-dialog__action-btn {
    width: 7.2rem;
    height: 2.7rem;
    padding: 0;
    font-size: 0.9rem;
    box-shadow: none;
    border-radius: 0;
    margin: auto auto 0.5rem auto;
    line-height: 0;
}

.transfer-invite-dialog__body {
    padding-left: 1rem;
    text-align: left;
    max-height: 24.3rem;
    overflow-y: auto;
}

.transfer-invite-dialog__footer {
    width: 100%;
    position: absolute;
    bottom: .5rem;
}

.transfer-invite-dialog__actions-selection-container {
    display: flex;
    flex-direction: row;
    margin: 0.9rem auto;
}

.transfer-invite-dialog__action-type {
    margin: auto;
}

