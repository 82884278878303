.permission-tabs-container {
    width: 100%;
}

.permission-tabs_tabs {
    cursor: pointer;
    float: left;
    margin-left: 5rem;
    text-align: left;
}

.permission-tabs_tabs span{
    margin-left: .5rem;
    margin-right: .5rem;
    font-family: Arial, Helvetica, sans-serif;
    font-size: medium;
    cursor: pointer;
    margin-bottom: -2px;
    -moz-user-select: none;
    -khtml-user-select: none;
    user-select: none;
    white-space: nowrap;
}

.permission-tabs_tabs span.active{
    font-weight: bold;
    border-bottom: 2px solid #26a69a;
}