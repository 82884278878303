.quick-answer-container {
    position: absolute;
    bottom: 100%;
    left: 4rem;
    right: 4rem;
    text-align: left;
    background-color: rgb(240, 240, 240);
    border-radius: 4px;
    box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 12%), 0 1px 5px 0 rgb(0 0 0 / 20%);
    padding: 4px 8px;
    z-index: 10;
}

.quick-answer {
    cursor: pointer;
    padding-left: .3rem;
    padding-right: .3rem;
    -moz-user-select: none;
    -khtml-user-select: none;
    user-select: none;
    display: flex;
    align-items: center;
    margin: 0.5rem 0;
    position: relative;
}

.quick-answer:hover .quick_answer__close {
    opacity: 1;
}

.quick_answer__counter {
    background-color: #26a69a;
    border-radius: 100%;
    padding: 0.5rem 1rem;
    font-size: small;
    color: white;
}

.quick_answer__text {
    font-size: 1.6rem;
    margin-left: 2rem;
    display: -webkit-inline-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.quick_answer__text.selected {
    background-color: #26a69a;
    border-radius: 4px;
}

.quick_answer__text.selected:hover {
    background-color: #26a69a;
    border-radius: 4px;
}

.quick_answer__text:hover {
    background-color: #26a69a;
    border-radius: 4px;
    color: #fff;
}

.quick_answer__close {
    opacity: 0;
    width: 2rem;
    text-align: center;
    font-size: initial;
    position: absolute;
    right: 0.5rem;
    top: 50%;
    transform: translateY(-50%)
}

.quick_answer__close:hover {
    background-color: #26a69a;
    border-radius: 4px;
    color: #fff;
}

.quick-answer:hover .quick_answer__text {
    background-color: #26a69a;
    color: #fff;
    border-radius: 4px;
}

