.contact-messenger {
    display: flex;
    flex-direction: row;
}

.contact-messenger .contact-messenger__icon {
    margin-top: 0.1rem;
    border-radius: 50%;
    cursor: pointer;
}

.contact-messenger p {
    margin: 0;
    margin-left: 4px;
}

.contact-messenger__detach-button {
    background: none;
    line-height: normal;
    color: #039be5;
    box-shadow: none;
    padding: 0;
    margin-left: 0.6rem;
    height: auto;
    text-transform: none;
    font-size: 1rem;
}

.contact-messenger__detach-button:hover {
    background: none;
    box-shadow: none;

}

.contact-messenger__detach-button:focus {
    background: none;
    box-shadow: none;
}

.contact-messenger__id {
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline;
}