.choose-message-content-type-dialog__container {
    position: relative;
    top: 6px;
    cursor: pointer;
}

.choose-message-content-type__menu-item {
    cursor: pointer;
    text-align: left;
    padding: 1rem 2rem;
    display: flex;
}

.choose-message-content-type__menu-item:hover {
    background: #26a69a;
    color: #fff;
}

.choose-message-content-type-dialog__menu {
    position: absolute;
    display: block;
    min-width: 4rem;
    bottom: 2rem;
    text-align: center;
    background-color: white;
    box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 12%), 0 1px 5px 0 rgb(0 0 0 / 20%);
    border-radius: 4px;
    z-index: 100;
}

.choose-message-content-type-dialog__dropdown-image {
    margin-right: 10px;
}

.choose-message-content-type-dialog__image {    
    opacity: 0.7;
}

.choose-message-content-type-dialog__image:hover {    
    opacity: 1;
}