.navmenu-container {
    margin: 0;
    background-color: rgb(102,102,102);
    height: 100%;
    min-height: 100vh;
}

.logo {
    color: white;
}

.logo__image {
    height: 4rem;
    display: block;
    margin-left: 0.5rem;
}

.logo__title {    
    content: url('../shared/images/WriterLogo.svg');
    background-repeat: no-repeat;    
    margin: 16px 0px 3px 0px;
    height: 18px;
}

.nav-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.navmenu__link-img {
    width: 2.4em;
    height: 2.4em;
    margin: auto;
}

.navmenu__link {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 1rem;
    color: white;
    width: 3em;
    font-size: 1.4rem;
}

.navmenu__link:hover .navmenu__link-img {
    transition: 0.3s;
    transform: translateY(-8px);
}

.navmenu__link-img.rounded {
    border-radius: 50%;
}