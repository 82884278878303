.channel-main {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
}

.channel-main__header {
    font-size: 4rem;
    font-weight: 600;
    text-align: center;
}