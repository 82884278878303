.mailing-main__header{
    font-size: 4rem;
    font-weight: 600;
    text-align: center;
}

.mailing-main {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
}