.operators-list__name {
    cursor: pointer;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none;   /* Chrome/Safari/Opera */
    -khtml-user-select: none;    /* Konqueror */
    -moz-user-select: none;      /* Firefox */
    -ms-user-select: none;       /* Internet Explorer/Edge */
    user-select: none;           /* Non-prefixed version, currently not supported by any browser */
}

.operators-list {
    height: calc(100% - 6rem);
    display: flex;
    flex-direction: column;
    overflow: auto;
    position: relative;
}

.operators-list .operators-list__btn-create {
    align-self: flex-start;
    margin: 1.5rem 4rem;
}

.operators-list .operators-list__avatar-cell {
    width: 7rem;
}

.operators-list .operators-list__buttons-cell {
    width: 5%;
}

