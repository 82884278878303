.contact-item-info__table-body tr {
    border: none;
    padding: 1rem;
}

.contact-item-info__table-body td {
    padding: 0;
    text-align: left;
}

.contact-item-info__avatar-container {
    width: 30%;
}

.contact-item-info__phone-cell td {
    padding-top: 1.2rem;
}

.contact-item-info__created-time-cell {
    line-height: 5rem;
    margin: auto;
    margin-top: 2rem;
}

.contact-item-info__localization-cell {
    margin: auto;
    line-height: 5rem;
}

.contact-item-info__container {
    height: 100%;
    padding: 3% 10%;
    display: flex;
    flex-direction: column;
    overflow: auto;
    position: relative;
}

.contact-item-info__table {
    width: 72rem;
}

.contact-item-info__actions {
    display: flex;
    width: 50%;
    margin: auto;
    margin-top: 0.8rem;
}

.contact-item-info__btn-save {
    margin-right: 0.3rem;
}

.contact-item-info__btn-delete {
    background-color: #d22e37;
    margin-left: 0.3rem;
}

.contact-item-info__btn-delete:hover {
    background-color: #e74c3c;
}

.contact-item-info__avatar {
    width: 8rem !important;
    height: 8rem !important;
    border-radius: 50%;
    cursor: pointer;
}

.contact-item-info__avatar svg {
    width: 100% !important;
    height: 100% !important;
}

.contact-item-info__telephone-container {
    max-width: 19.2rem;
}

.contact-item-info__phone-input-cell {
    display: flex;
    flex-direction: row;
    max-width: 19.2rem;
}

.contact-item-info__merge-button {
    line-height: 0;
    width: 4rem;
    height: 1.2rem;
    margin: auto 0;
    margin-left: 2.8rem;
    padding: 0;
    font-size: 0.6rem;
}

.open-comment-in-full-screen{
    border: 0;
    background: transparent;
    cursor: pointer;
}

.open-comment-in-full-screen:hover{
    background: transparent;
    color: #26a69a;
}

.open-comment-in-full-screen:focus{
    background: transparent;
}