.transfer-question-popup select {
    display: inline-block;
}

.transfer-question-popup .transfer-question-popup__header-info {
    color: #cccccc;
    max-height: 110px;
    overflow: clip;
}

.transfer-question-popup .transfer-question-popup__header-bold {
    font-weight: 700;
    word-break: break-word;
}

.transfer-question-popup__button {
    margin-left: 0.2rem !important;
    margin-right: 0.2rem !important;
}

.transfer-question-popup .transfer-question-popup__button-apply {
    background-color: #d6ac17;
}

.transfer-question-popup .transfer-question-popup__button-cancel {
    background-color: #d22e37;
}

.transfer-question-popup .transfer-question-popup__option-active {
    background-color: #b9f6ca;
    font-size: 1.75rem;
}

.transfer-question-popup .transfer-question-popup__option-inactive {
    background-color: #ffcdd2;
    font-size: 1.75rem;
}

#select-target-label-label {
    font-size: initial;
}

#select-target-label {
    height: 40px;
}

.MuiSvgIcon-root {
    width: 1.5em !important;
    height: 1.5em !important;
}

.MuiInputBase-root {
    font-size: large !important;
}

.select-target-option {
    font-size: large;
}