.channel-operators-config {
    width: 50%;
}

.channel-operators-config__operator-avatar {
    border-radius: 50%;
    width: 5.4rem !important;
    height: 5.4rem !important;
}

.channel-operators-config__operator-avatar svg {
    width: 100%;
    height: 100%;
}

.channel-operators-config__operator-priority {
    width: 100%;
    display: block;
    color: black;
    outline: none;
    padding: 0;
    margin: 0;
    margin-bottom: 1rem;
    border: none;
    border-bottom: 1px solid #9e9e9e;
    transition: box-shadow .3s, border .3s;
    font-size: 1.8rem;
}

.channel-operators-config__operator-name-cell {
    min-width: 14.4rem;
    height: 2.4rem;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: normal;
    word-break: break-word;
}

.channel-operators-config .modal-body {
    height: 54rem;
}

.channel-operators-config__operator-name {
    font-size: 1.8rem;
}

.channel-operators-config__operators {
    max-height: 100%;
    overflow-y: scroll;
}

.channel-operators-config__operator-enable-cell {
    min-width: 5.4rem;
}

.channel-operators-config__operator-enable-cell [type="checkbox"] {
    position: fixed;
}

.channel-operators-config__operator-avatar-cell {
    min-width: 5.8rem;
}

.channel-operators-config .modal-title {
    width: 100%;
}

.channel-operators-config__header {
    display: flex;
    flex-direction: row;
}

.channel-operators-config__header .btn {
    margin-left: auto;
    background-color: #d22e37;
}

.channel-operators-config .modal-footer{
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.channel-operators-config__operator-priority-cell {
    min-width: 16rem;
}

.channel-operators-config__header-title {
    font-size: 2.4rem;
    font-weight: bold;
}