.topic-search-container {
  height: 100%;
  width: 100%;
}

.topic-search-header {
  font-size: 4rem;
  font-weight: bold;
}

.topic-pairs-search-input {
  margin: 1.5rem 4rem;
  height: 4rem;
  line-height: 4rem;
  max-width: 72%!important;
  min-width: 62%;
  float: left;
}

.topic-pairs-search-table {
  height: calc(100% - 13rem);
  width: 100%;
  overflow: auto;
  position: relative;
}

.topic-pairs-search-table__header {
  display: grid;
  grid-template-columns: 1fr 1fr 0.5fr;
  padding: 1rem;
  font-weight: bold;
}

.topic-pairs-search-table__body {
  padding: 1rem;
  min-width: 108rem;
}

.topic-pairs-search-table__item {
  width: 100%;
  height: 100%;
  position: relative;
  margin-top: 1rem;
  margin-bottom: 1rem;
  line-height: 3.5rem;
  background-color: #fff;
  box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 12%),
    0 1px 5px 0 rgb(0 0 0 / 20%);
  border-radius: 2px;
  padding: 1rem 2rem;
  overflow: hidden;
  min-width: 60rem;
  display: flex;
  flex-direction: column;
}

.show-hide-checkbox {
  padding: 0;
  margin: 0;
  opacity: 0 !important;
  z-index: 100;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.show-hide-checkbox:after {
  content: "";
  width: 0;
  height: 0;
  position: absolute;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid #26a69a;
  cursor: pointer;
}

.show-hide-checkbox:checked::after {
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid #26a69a;
  border-top: none;
}

.show-hide-checkbox:checked + .topic-pairs-search-table__item-main {
  height: 100%;
}

.topic-pairs-search-table__item-main {
  grid-template-columns: 1fr 1fr;
  display: grid;
  min-height: 8rem;
  overflow: hidden;
}

.pane-chat-visible .topic-pairs-search-table__item-main {
  grid-template-columns: 1fr 1fr 0.5fr;
}

.topic-pairs-search-question {
    text-align: left;
    min-width: 20rem;
    word-wrap: break-word;
}

.topic-pairs-search-answer-text {
  transition: 0.4s;
  word-wrap: break-word;
  text-align: left;
  height: 100px;
  overflow: hidden;
}

.topic-pairs-search-answer-text.opened {
  height: auto;
}

.topic-pairs-search-table__item_info {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  column-gap: 1rem;
}

.topic-pairs-search-table .tag {
  padding: 0.1rem 0.8rem;
  border-radius: 8px;
  border: 1px solid #26a69a;
  color: #26a69a;
  font-size: 1.4rem;
  line-height: 2.4rem;
}

.topic-pairs-search-question__list {
  margin: 0;
  padding: 0;
}

.topic-pairs-search-button {
  display: none;
  position: relative;
  z-index: 101;
}

.pane-chat-visible .topic-pairs-search-button {
  display: block;
}

.topic-pairs-search-table__item-collapse-button {
  width: 0;
  height: 0;
  margin-top: 8px;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid #26a69a;
  cursor: pointer;
  align-self: center;
}

.topic-pairs-search-table__item-collapse-button.opened {
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid #26a69a;
  border-top: none;
}
