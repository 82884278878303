.MuiPaper-root .send-message-failed {
    background-color: rgb(255, 204, 204);
}

.sending-message-in-proccess {
    opacity: .5;
}

.sending-message-resend {
    float: right;
}

.sending-message-spinner {
    float: right;
}

.sending-message-text {
    float: left;
    padding-left: 1.4rem;
    word-break: break-word;
}