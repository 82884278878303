.chat-close-container {
    max-height: 30rem;
    overflow: auto;
}

.chat-close-body {
    margin-left: 1rem;
    width: 90%;
}

.chat__close-reason-container {
    width: 700px;
    top: 45%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    max-width: none;
}

.close-reason-container__question {
    color: black;
}

.chat__close-reason-container .modal-title {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0;
    font-size: 2.6rem;
}

.close-reason-container__button {
    width: 130px;
    margin: 0px 10px 0px 10px !important;
}

.close-reason-container__select {
    margin: 20px 0px 20px 0px;
}

.close-reason-container_spinner {
    margin-left: auto;
    margin-right: auto;
    display: table;
}