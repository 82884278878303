body {
  margin: 0;
  font-family: "Gilroy" -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100%;
  font-size: calc(1rem + 0.4vw);
}

label {
  font-size: 1.4rem;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

input:not([type]),
input[type="text"]:not(.browser-default),
input[type="password"]:not(.browser-default),
input[type="email"]:not(.browser-default),
input[type="url"]:not(.browser-default),
input[type="time"]:not(.browser-default),
input[type="date"]:not(.browser-default),
input[type="datetime"]:not(.browser-default),
input[type="datetime-local"]:not(.browser-default),
input[type="tel"]:not(.browser-default),
input[type="number"]:not(.browser-default),
input[type="search"]:not(.browser-default),
textarea.materialize-textarea {
  height: 5.4rem;
  font-size: 1.6rem;
}

select {
  height: 5.4rem;
}

textarea {
  cursor: auto;
}

.btn i,
.btn-large i,
.btn-small i,
.btn-floating i,
.btn-large i,
.btn-small i,
.btn-flat i {
  font-size: 2rem;
}

.hidden {
  opacity: 0;
  width: 0px;
  overflow: hidden;
  position: absolute;
}

.card-table {
  border-collapse: separate;
  border-spacing: 1rem;
  word-break: break-word;
}

.card-table th {
  word-break: initial;
  font-weight: normal;
}

.card-table thead th {
  word-break: initial;
  font-weight: bold;
}

.card-table > tbody > tr {
  border: none;
  box-shadow: 0 2px 2px 0 #00000024, 0 3px 1px -2px rgb(0 0 0 / 12%),
    0 1px 5px 0 rgb(0 0 0 / 20%);
}

.card-table > thead > tr {
  box-shadow: none;
}
