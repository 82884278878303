.transfer-operator-tab-container {
    display: flex;
    justify-content: space-around;
    border-bottom: 1px solid rgb(161, 161, 161);
}

.transfer-operator_tab {
    width: 30%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
}

.transfer-operator_tab.selected {
    border-bottom: 1px solid;
    font-weight: bold;
}

.transfer-tier-row {
    text-align: left;
    padding: .3rem;
    width: 98%;
    margin-left: auto;
    margin-right: auto;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
}

.transfer-tier-selected-row {
    float: right;
}