.smiles-container-dropdownToggle {
  background: none;
  border: none;
  box-shadow: none;
  text-transform: none;
  padding: 5px 0px 0px 0px;
}

.smiles-container-dropdownToggle:hover {
  background: none;
  border: none;
  box-shadow: none;
}

.smiles-container-dropdownToggle:focus {
  background: none;
  border: none;
  box-shadow: none;
}

.dropdown-menu {
  outline: none;
}

.smiles-container {
  position: relative;
  bottom: 0;
  right: 3rem;
}

.smiles-button {
  color: rgba(0, 0, 0, 0.87);
  cursor: pointer;
  font-size: 2.2rem !important;
  opacity: 0.7 !important;
}

.smiles-button:hover {
    opacity: 1 !important;
}

.emoji-mart-search {
  display: none;
}

.emoji-mart-anchors {
  background-color: white;
}

.emoji-mart-anchor:focus {
  background-color: white;
}

.emoji-mart-preview {
  height: 5px !important;
  background-color: #c1c1c1;
}

.emoji-mart-preview-emoji {
  display: none;
}

.emoji-mart-preview-skins {
  display: none;
}

.smiles-dialog__menu .dropdown-menu {
  z-index: 10;
}