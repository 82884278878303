.chat-item {
    width: 7.2rem;
    height: 5.4rem;
    margin-top: 0.4rem;
    margin-bottom: 0.4rem;
    margin-left: 0.4rem;
}

.chat-panel__chat-item__image {
    height: 100%;
    width: 100%;
}

.chat-panel__chat-item__count {
    position: relative;
    top: -6.8rem;
    left: 3.6rem;
    width: 1.8rem;
    height: 1.8rem;
    border-radius: 50%;
    font-size: 1.3rem;
}

.chat-panel__chat-item__chat-owner {
    background-color: crimson;
}

.chat-panel__chat-item__invited {
    background-color: rgb(194, 194, 35);
}

.chat-panel__chat-item__chat-id {
    position: relative;
    top: -4.5rem;
    font-size: smaller;
    font-style: italic;
    font-weight: 700;
    color: crimson;
    text-shadow: -1px 1px 0 #fff,
                1px 1px 0 #fff,
                1px -1px 0 #fff,
                -1px -1px 0 #fff;
}

.chat-panel__selected-chat{
    background-color: rgb(133, 133, 133);
}

.channel-type-telegram img {
    content: url("./../../shared/images/Chat/telegram.svg");
}

.channel-type-viber img {
    content:url("./../../shared/images/Chat/viber.svg");
}

.channel-type-vk img {
    content:url("./../../shared/images/Chat/vk.svg");
}

.channel-type-widget img {
    content: url("./../../shared/images/Chat/widget.svg");
}

.channel-type-facebook img {
    content: url("./../../shared/images/Chat/facebook.svg");
    width: 51px;
    height: 51px;
}

.channel-type-wechat img {
    content: url("./../../shared/images/Chat/wechat.svg");
}

.channel-type-instagram img {
    content: url("./../../shared/images/Chat/instagram.svg");
    width: 60px;
    height: 60px;
}

.channel-type-uniformwidget img {
    content: url("./../../shared/images/Chat/uniformwidget.svg");
    width: 60px;
    height: 60px;
}

.channel-type-ednawhatsapp img {
    content: url("./../../shared/images/Chat/whatsapp-logo.svg");
    width: 60px;
    height: 60px;
}

.channel-type-whatsapp img {
    content: url("./../../shared/images/Chat/whatsapp-logo.svg");
    width: 60px;
    height: 60px;
}

.channel-type-dewinowhatsapp img {
    content: url("./../../shared/images/Chat/whatsapp-logo.svg");
    width: 60px;
    height: 60px;
}