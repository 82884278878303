.topic-editor-header {
    height: 6.4rem;
    max-height: 6.4rem;
    line-height: 6.4rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1.8rem;
    font-size: 3.6rem;
}

.topic-editor-header .topic-editor-header__content {
    display: flex;
}

.topic-editor-header .topic-editor-header__content-grow {
    flex-grow: 1;
    max-width: 50%;
    display: flex;
}

.topic-editor-header .topic-editor-header__identifier {
    color: #cccccc;
}

.topic-editor-header .topic-editor-header__name {
    font-weight: 700;
    padding-left: 0.9rem;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
}

.topic-editor-header .topic-editor-header__button {
    margin-left: 0.4rem;
    margin-right: 0.4rem;
}

.topic-editor-header__button_synonyms {    
    width: 150px;
    margin-right: 1.8rem;
}

.topic-editor-header__button_export_pairs {
    width: 150px;
    margin-right: 1.8rem;
}

.topic-editor-header__button-import-pairs {
    width: 150px;
    margin-right: 1.8rem;
}

.topic-editor-header__button-publish {
    width: 150px;
}

.custom-spinner {
    position: relative;
    top: unset;
    left: unset;
    transform: unset;
}