.message_box {
    min-width: 50rem;
}

.message-box__header {
    width: 100%;
    height: 10%;
    display: flex;
    flex-direction: row;
    margin: 0;
    padding: 0;
}

.message-box__title {
    margin: inherit;
    padding: inherit;
    text-align: left;
    width: 90%;
    font-size: 2rem;
}

.message-box__close {
    width: 20%;
    background-color: #d22e37;
    border: none;
}

.message-box__body {
    height: 90%;
    outline: none;
    border: none;
    resize: none;
    width: 100%;
    word-wrap: break-word;
    font-size: 2rem;
}

.message-box__footer {
    width: 100%;
    text-align: right;
}

.message-box__confirm-buttons--reject {
    background-color: #d22e37;
}
