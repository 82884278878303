#toast-container {
    margin-top: 1rem;
    position: fixed;
    top: 1rem;
    right: 1rem;
    left: unset !important;
}

.toast-warning {
    flex-direction: row-reverse;
}

.toast-message {
    font-size: 1.8rem !important;
}

button.toast-close-button {
    top: -4px;
    right: -4px;
    font-size: 34px;
}