.channel-table-header {
  display: grid;
  grid-template-columns: 30% 15% 15% 10% 20%;
  column-gap: 1rem;
  text-align: start;
  padding: 1rem 2rem;
  font-weight: bold;
}

.channel-list__name {
  cursor: pointer;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Chrome/Safari/Opera */
  -khtml-user-select: none; /* Konqueror */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently not supported by any browser */
}

.channel-list__buttons-cell {
  width: 5%;
}

.channel-list__enabled-cell {
  width: 10rem;
}

.channel-list {
  height: calc(100% - 6rem);
  display: flex;
  flex-direction: column;
  overflow: auto;
  position: relative;
}

.channel-list-table {
  min-width: 122rem;
  margin: 0 1rem;
}

.channel-list__btn-create-operator {
  /* width: 11rem;
    margin: 1.5rem 4rem; */
}

.channel-list__header {
  margin: 1.5rem 4rem;
  display: flex;
  justify-content: flex-start;
}
