.message-form {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 10px 8px 0 8px;
    transition: 0.3s;
    border-bottom: 2px solid rgba(0, 0, 0, 0.87);
    padding: 0;
}

.message-form:focus-within {
    border-bottom: 2px solid #3f51b5;
}

.message-form .message-form__send,
.message-form .message-form__input {
    height: 75%;
    outline: none;
    border: none;
}

.message-form .message-form__input {
    resize: none;
    border: none;
    margin-right: 3px;
    margin-bottom: 0px;
    vertical-align: middle;
    transition: 0.3s;
}

.message-form .message-form__input:hover,
.message-form .message-form__input:focus {
    border: none !important;
    box-shadow: none !important;
}

.message-form .message-form__send {
    background: none;
    box-shadow: none;
}

.message-form .message-form__send:hover {
    border: none;
    box-shadow: none;
}

.message-form__send .material-icons {
    color: #718093;
    transition: 0.3s;
}

.message-form__send .material-icons:hover {
    color: #2f3640;
}
