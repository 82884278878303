.permissions_user-container {
    margin-top: 9rem;
    margin-left: auto;
    margin-right: auto;
    width: 95%;
}

.permission_user-select {
    position: absolute;
    display: block;
    width: 25rem;
    margin-left: 20rem;
    margin-top: -10rem;
}