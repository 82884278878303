.mailing-list__header {
    margin: 1.5rem 4rem;
    display: flex;
    justify-content: space-between;
}

.mailing-list {
    height: calc(100vh - 6rem);
    overflow: auto;
    position: relative;
}

.mailing-list__table {
    min-width: 60rem;
}

.mailing-list thead th {
    text-align: center;
}

.mailing-list__table tbody td {
    text-align: center;
} 

.mailing-list__mailing-id-block {
    width: 2rem;
}

.mailing-list__buttons-block {
    display: flex;
    justify-content: flex-end;
    flex-wrap: nowrap;
    column-gap: 1rem;
}

.mailing-list__buttons-block button {
    word-break: initial;
}

.mailing-list__mailing_delete_button {
    background-color: #d22e37;
}

.mailing-list__mailing_edit_button {
    background-color: #1696a6;
}

.mailing-list__mailing_start_button {
}

.mailing-list_id {
    min-width: 8rem;
    color: #ccc;
    font-size: 1.6rem;
}

.mailing-list__created-date {
    min-width: 12rem;
}