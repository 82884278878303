.contact-list-container {
    display: flex;
    flex-direction: column;
    min-width: 60rem;
}

.contact-list__header {
    display: flex;
    flex-direction: row;
    margin: 1.5rem 4rem;
    align-items: center;
    justify-content: space-between;
}

.contact-list__search {
    flex-grow: 1;
    margin-left: 3rem;
}

.contact-list__header .btn {
    display: flex;
    align-items: center;
    justify-content: center;
}

.contact-list-table {
    margin-top: 1.5rem;
}

.contact-list-table th, td {
    text-align: center;
}

.contact-list__btn-more {
    width: 4rem;
    padding: 0;
    margin: 1rem auto;
}

.contact-list__no-contacts-text {
    margin-top: 1.8rem;
    text-align: center;
    font-weight: 500;
    font-size: 2.3rem;
}
.contacts-list__body {
    overflow: auto;
    height: calc(100vh - 12.6rem);
}

.contacts-list__body.splittered {
    left: 1rem;
    right: 0rem;
}

th.channel-list__messenger-header {
    text-align: left;
}