.app-container {
  text-align: center;
  display: flex;
  flex-direction: row;
  min-width: 30rem;
  min-height: 100%;
}

.app-container .app__navmenu {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 10rem;
  height: 100%;
}

.app-container .app__chats-panel {
  position: fixed;
  right: 0;
  bottom: 0;
  width: 8rem;
  height: 100%;
}

.app-container .app__router {
  width: calc(100% - 18rem);
  min-width: 60rem;
  height: 100vh;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  position: fixed;
  left: 10rem;
  right: 8rem;
  overflow: hidden;
}

.app__router .app__router--split-pane {
}

*::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.3);
  background: #f5f5f5;
  border-radius: 10px;
}

*::-webkit-scrollbar-thumb {
  background-color: #999;
  border-radius: 10px;
  box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.3);
  border: 2px solid #f5f5f5;
}

/* Back to defaults after applying materialize.css */
.wmde-markdown [type="checkbox"]:not(:checked),
.wmde-markdown [type="checkbox"]:checked {
  position: initial;
  opacity: initial;
  pointer-events: initial;
  box-sizing: initial;
  padding: initial;
}

/* Back to defaults after applying materialize.css */
.wmde-markdown ul:not(.browser-default) > li:not(.task-list-item) {
  list-style-type: initial;
}
