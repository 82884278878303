.phone-input__input {
    margin-left: 3.7rem !important;
}

.phone-input__container .is-invalid {
    border-bottom: 1px solid red !important;
}

.phone-input__container .is-invalid:focus{
    box-shadow: 0 1px 0 0 red !important;
}

.phone-input__container {
    width: 100% !important;
    margin-right: 1rem;
}

.phone-input__container .selected-flag {
    padding: 0 !important;
}

.phone-input__container .flag-dropdown::before  {
    content: none !important;
}
