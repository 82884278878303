.paging-container {
    display: flex;
    gap: .8rem;
}

.paging-tile {
    border: 2px solid #26a69a;
    padding: .5rem 1rem;
    background-color: rgba(240, 255, 255, 0.4);
    cursor: pointer;
    border-radius: 5px;
    -webkit-touch-callout: none;
    -webkit-user-select: none; 
    -khtml-user-select: none;
    -moz-user-select: none; 
    -ms-user-select: none; 
    user-select: none;
}

.paging-tile.selected-tile {
    background-color: rgba(240, 255, 255, 0.8);
    font-weight: bolder;
    border: 3px solid #26a69a;
    text-decoration: underline;
}
