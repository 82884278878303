.channel-item__channel-actions {
  display: flex;
  flex-direction: row;
  width: 30%;
}

.channel-item__channel-actions .btn {
  margin: 0 0.5rem;
}

.channel-item__btn-remove {
  background-color: #d22e37;
}

.channel-item__btn-restart {
    background-color: #d22e37;
}

.channel-item__channel-type {
  display: block;
  color: black;
  outline: none;
  padding: 0;
  margin: 0;
  margin-bottom: 0.9rem;
  border: none;
  border-bottom: 1px solid #9e9e9e;
  transition: box-shadow 0.3s, border 0.3s;
}

.channel-item__channel-type:focus {
  display: block;
  border: none;
  outline: none;
  border-bottom: 1px solid #26a69a;
  box-shadow: 0 1px 0 0 #26a69a;
}

.channel-item__enabled-cell {
  text-align: center;
}

[type="checkbox"]:checked
  + .channel-item__is-enabled.not-started:not(.lever):before {
  border-right: 2px solid red;
  border-bottom: 2px solid red;
}

.channel-item-cell {
  padding-right: 1.8rem;
}

.channel-item__operators {
  width: auto;
  padding: 0;
}

.channel-item {
  box-shadow: 0 2px 2px 0 #00000024, 0 3px 1px -2px rgb(0 0 0 / 12%),
    0 1px 5px 0 rgb(0 0 0 / 20%);
  margin: 2rem 0;
  padding: 1.8rem 3.6rem;
  overflow: hidden;
}

.channel-item__operators-row {
  padding-bottom: 5px;
}

.channel-item-content {
  display: grid;
  grid-template-columns: 30% 15% 15% 10% 20%;
  column-gap: 1.8rem;
  text-align: start;
}

.channel-item-id {
  left: 2rem;
  margin-top: -1.4rem;
  position: absolute;
  font-style: italic;
  font-size: small;
  color: #ccc;
}
