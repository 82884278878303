.tags-list__container {
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid #9e9e9e;
    line-height: 1.2;
}

.tags-list__tag {
    padding: 0.4rem 0.6rem;
    margin: 0.1rem 0.05rem;
    border-radius: 0.2rem;
    background: #26a69a;
    color: #fff;
    display: flex;
    align-items: center;
    flex-direction: row;
    white-space: nowrap;
}

.tags-list__tag .operator-tag__container {
    display: flex;
    flex-direction: row;
}

.operator-tag__container .operator-tag__avatar {
    border-radius: 50%;
    margin: auto 0;
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 0.4rem;
}

.operator-tag__container .operator-tag__avatar svg {
    width: 100%;
    height: 100%;
}

.tags-list__tag--close {
    margin-left: 0.6rem;
    color: #fff;
    cursor: pointer;
}

.tags-list__tag--close .material-icons {
    font-size: 1.4rem;
}

.tags-list__tag--close:hover {
    color: black;
}