.topic-editor-synonyms_input {
  min-height: 50vh;
  margin-top: 0.5rem;
  resize: none;
  font-size: 1.8rem;
}

.topic-editor-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.topic-editor-synonyms-close {
  position: absolute;
  right: 5rem;
  top: 5rem;
}

.topic-editor-synonyms_description {
  font-size: 1.6rem;
  font-weight: bold;
}
