.mailing-item-info {
    padding: 0 1rem;
    min-width: 60rem;
}

.mailing-table th {
    text-align: center;
}

th.mailing-item-header__contact {
    text-align: left;
}

.mailing-table > tbody > tr > td {
    max-width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.mailing-item-info__avatar {
    min-width: 6.4rem;
    padding-left: 1rem;
}

.mailing-item-info__recipient-name {
    min-width: 10rem;
    text-align: left;
}

.mailing-item-info__messenger {
    width: 30%;
    min-width: 16rem;
    text-align: left;
}

.mailing-item__mailing-status {
    min-width: 10rem;
}

.mailing-item-info__link {
    min-width: 10rem;
}

.mailing-item-info__name-block {
    width: 100%;
    float: left;
    display: block;
}

.mailing-item-info__name-label {
    float: left;
    display: block;
    min-width: 7.2rem;
    height: 100%;
    margin-top: 1.8rem;
    margin-right: 2rem;
}

.mailing-item-info__name-input {
    width: 27rem !important;
    text-align: left;
    float: left;
}

.mailing-item-info__text-block {
    margin-top: 9rem;
    width: 100%;
}

.mailing-item-info__text-label {
    float: left;
    min-width: 9rem;
    height: 100%;
    margin-top: 1.8rem;
    margin-right: 2rem;
}

.mailing-item-info__text-input {
    width: 70% !important;
    text-align: left;
    float: left;
    height: 7.2rem;
}

.mailing-item-info__channels-block {
    width: 75%;
    float: left;
    display: block;
    margin-top: 3.6rem;
    height: 3.6rem;
}

.mailing-item-info__channels-label {
    float: left;
    min-width: 9rem;
    height: 100%;
}

.mailing-item-info__channels-choose {
    width: 22rem !important;
    text-align: left;
    float: left;
    height: 2.6rem;
}

.mailing-item-info__channels-choose .visible-checkbox {
    margin-left: 1.8rem;
    margin-right: .5rem;
    position: relative;
    opacity: 1; 
    pointer-events: all;
}

.mailing-item-info__choosen-channels {
    float: left;
}

.mailing-item-info__channel-container {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.mailing-item-info__channel-image {
    width: 2.6rem;
    margin-left: .5rem;
}

.mailing-item-info__channel-externalid {
    vertical-align: 0.6rem;
    margin-left: 1.8rem;
}

.mailing-item-info__recipients-source-block {
    width: 100%;
    float: left;
    display: block;
}

.mailing-item-info__recipients-source-radio {
    float: left;
    width: 100%;
    text-align: left;
}

.mailing-item-info__recipients-source-radio [type="radio"]:not(:checked), [type="radio"]:checked {
    position: relative !important;
    opacity: 1 !important;
    pointer-events: all !important;
    margin-right: 1.8rem;
    margin-left: .9rem;
}

.mailing-item-info__save-block {
    float: right;
}

.mailing-item-info__recipients-list-block {}

.mailing-item-info__recipient-photo {
    width: 5.4rem !important;
    height: 5.4rem !important;
    border-radius: 50%;
}

.mailing-item-info__recipient-photo svg {
    width: 100%;
    height: 100%;
}

.mailing-item-info__channel-sent-label {
    display: inline-block;
    vertical-align: 0.8rem;
}

.mailing-item-info__chat-link {
    cursor: pointer;
}

.mailing-item__mailing-status{
    width: 30%;
}

.mailing-item-info-operators {
    width: 100%;
    float: left;
}

.mailing-item-info-operators select {
    font-size: 1.4rem !important;
    padding: 0rem;
    height: 3rem;
    display: block;
    color: white;
    border: 0;
    border: none;
    color: black;
    width: fit-content;
    margin-top: 1.5rem
}

.mailing-item-info-operator-option {
    background-color: rgb(0, 199, 0) !important;
}