.knowledge-base-main__header {
    font-size: 4rem;
    font-weight: 600;
    text-align: center;
}

/* .layout-pane .knowledge-base__main {
    margin: 0 1rem;
} */

.knowledge-base__main {
    display: flex;
    flex-direction: column;
}