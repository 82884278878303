.license-manage-container {
    width: 60%;
    margin-left: auto;
    margin-right: auto;
}

.license-manage-container_license-lable {
    text-align: left;
    margin-bottom: 1rem;
    font-weight: 600;
}

.license-manage-container_license {
    width: 100%;
    height: 30rem;
}

.license-manage-header {
    font-size: 4rem;
    font-weight: 600;
    text-align: center;
}

.license-manage-save {
    float: right;
    margin-right: 5rem;
    margin-top: 1rem;
}