.message {
    display: flex;
}

.message__answer {
    justify-content: flex-start;
    margin: 10px 0 10px 20px;
}

.message__question {
    justify-content: flex-end;
    margin: 10px 20px 10px 10px;
}

.message__content {
    max-width: 80%;
    width: auto;
    background-color: #eceff1;
    border-radius: 4px;
    border: 1px solid rgba(1, 1, 1, 0.2);
    box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 
                0px 1px 1px 0px rgba(0,0,0,0.14),
                0px 1px 3px 0px rgba(0,0,0,0.12);
    overflow-wrap: break-word;
}

.message__question .message__content {
    text-align: right;
    padding: 5px 5px 5px 5px;
    background-color: white;
}

.message__answer .message__content {
    padding: 5px 5px 5px 5px;
    background-color: #eceff1;
}

.message__answer .message__content tbody td {
    vertical-align: middle;
}

.message__content .message__created-date {
    color: #78909c;
    font-size: 0.75rem;
    font-style: italic;
}
