.md-editor-container .tag {
  padding: inherit;
  border-radius: inherit;
  border: inherit;
  color: inherit;
  font-size: inherit;
  font-weight: bold;
  line-height: inherit;
}

.nlp-md-editor .w-md-editor-text,
.nlp-md-editor .wmde-markdown-color,
.nlp-md-editor .w-md-editor-text-pre,
.nlp-md-editor .w-md-editor-text-pre > code,
.nlp-md-editor textarea {
  line-height: 1.8rem !important;
  font-size: 1.4rem !important;
  font-family: Arial, Helvetica, sans-serif !important;
}

.nlp-md-editor .w-md-editor-text-input {
  padding: 10px !important;
}