.chats-main {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    position: relative;
    overflow: hidden;
    min-width: 60rem;
}

.chats-main__header {
    font-size: 4rem;
    font-weight: 600;
    text-align: center;
}