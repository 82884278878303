.answer-holder{
    width: 90%;
    display: flex;
    flex-direction: column;
    margin: 3px 0 3px 5px;
    border-bottom: #b2bec3 1px solid;
}

.answer-holder__header{
    display: flex;
    flex-direction: row;
}

.answer-holder__link{
    margin-right: 6px;
    cursor: pointer;
}

.answer-holder__body {
    text-align: left;
}

p {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
}