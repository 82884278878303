.author-name {
    font-size: 1.4rem;
    font-weight: bold;
    width: 100%;
    height: 2.2rem;
    color: black;
    text-transform: none;
}

.author-name.dropdown-toggle {
    line-height: 0;
}

.author-name.client-message-type {
    text-align: right;
    border-radius: 2px;
    padding-right: .4rem;
    background: linear-gradient(to right,  rgba(65, 191, 255, 0), rgba(233, 248, 255, 0.5), #26a69aa1);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    direction: rtl;
}

.author-name.operator-message-type {
    text-align: left;
    border-radius: 2px;
    padding-left: .4rem;
    margin-bottom: 6px;
    background: linear-gradient(to right, #26a69aa1, rgba(233, 248, 255, 0.5), rgba(65, 191, 255, 0));
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.author-name.chat-info-messageType {
    min-width: 100% !important;
    height: 2rem;
    background-color: rgb(185, 196, 196);
    font-size: 1rem;
    margin-top: 1rem;
    display: inline-table;
}

.author-name__dropdown-actions {
    display: flex;
    flex-direction: column;
    top: 0rem !important;
    left: 0rem !important;
    z-index: 1000;
    background-color: rgb(255, 255, 255);
    box-shadow: 0px 0px 2px 0px #26a69a;
    border-radius: 2px;
    text-align: center;
}

.dropdown-actions__closed {
    display: none;
}

.author-name__action {
    font-size: 1.2rem;
    font-weight: 500;
    padding: 1rem;
    line-height: 0;
    height: 1.8rem;
    background: none;
    color: black;
    box-shadow: 0px 1px 2px 1px #000000;
}

.author-name__action:hover {
    background: #26a69a;
    color: #fff;
}

.author-name__action:focus {
    background: #26a69a;
    color: #fff;
}