.chats-panel__container {
    height: 100%;
    background-color: rgb(102,102,102);
    display: flex;
    flex-direction: column;
}

.chat-panel__operator-status-container select {
    font-size: 1.4rem !important;
    padding: 0rem;
    height: 3rem;
    display: block;
    color: white;
    border: 0;
    border: none;
}

.chat-panel__selected-status-1 {
    background-color: rgb(0, 199, 0);
}

.chat-panel__selected-status-2 {
    background-color: rgb(206, 206, 58);
}

.chat-panel__selected-status-3 {
    background-color: red;
}

.chat-panel__operator-status-online {
    background-color: rgb(0, 199, 0) !important;
}

.chat-panel__operator-status-afk {
    background-color: rgb(206, 206, 58) !important;
}

.chat-panel__operator-status-offline {
    background-color: red !important;
}

.chat-panel__chat-items-container {
    margin-top: auto;
    padding: 0;
}

.chats-panel__logout {
    cursor: pointer;
    margin: 1rem auto 0.3rem auto;
    width: 7rem !important;
    height: 7rem !important;
    border-radius: 50%;
}

.chats-panel__logout svg {
    width: 100% !important;
    height: 100% !important;
}

.chat-panel__operator-name {
    font-size: 1.4rem;
    color: rgb(184, 184, 184);
}

.profile-dropdown__item {
    padding: 0.2rem;
    background: none;
    border: none;
    text-align: left;
    font-size: 1rem;
}

    .profile-dropdown__item.first {
        padding: 0.4rem 0.4rem 0.2rem 0.4rem;
    }

    .profile-dropdown__item.last {
        padding: 0.2rem 0.4rem 0.4rem 0.4rem;
    }

    .profile-dropdown__item.mid {
        padding: 0.2rem 0.4rem 0.2rem 0.4rem;
    }

    .profile-dropdown__item:hover {
        transition: 0.3s;
        background: #34495e;
    }

.profile-dropdown {
    position: fixed;
    right: 0.1rem;
    top: 4rem;
}

.profile-dropdown__menu {
    display: flex;
    flex-direction: column;
    border: 1px solid black;
    background: white;
}

.profile-dropdown__link {
    color: black;
}