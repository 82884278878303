.tag-searching-input__container {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    overflow-x: auto;
    flex-grow: 1;
}

input.tag-searching-input__input {
    min-width: 10rem;
    border: none !important;
    border-bottom: 1px solid #9e9e9e !important;
    margin-bottom: 0 !important;
    line-height: 1.5 !important;
    padding-left: 0.4rem !important;
    height: 3rem;
}

.clear-filter {
    color: #666666;
    cursor: pointer;
    border-bottom: 1px solid #9e9e9e;
    display: flex;
    align-items: center;
    height: 3rem;
}

.clear-filter:hover {
    color: #fff;
    background: #26a69a;
}