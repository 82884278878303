.channel-operator-list {
    display: flex;
    flex-direction: column;
    max-width: 90rem;
}

.channel-operator-list__operators {
    display: flex;
    flex-direction: row;
}

.channel-operator-list__title {
    font-size: 1.8rem;
    font-weight: 700;
    margin-bottom: 0.5rem;
    text-align: flex-start;
}

.channel-operator-list__operator-avatar {
    width: 6.4rem !important;
    height: 6.4rem !important;
    border-radius: 50%;
}

.channel-operator-list__operator-avatar svg {
    width: 100% !important;
    height: 100% !important;
}

.channel-operator-list__operator-name {
    position: relative;
    top: -0.2rem;
    left: 0;
    text-align: center;
    word-break: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
            line-clamp: 2; 
    -webkit-box-orient: vertical;
}

.channel-operator-list__btn-add-operator {
    background: none;
    min-width: 6.4rem;
    height: 6.4rem;
    border-radius: 50%;
    margin: 0px 0.4rem 0px 0.4rem;
    cursor: pointer;
    box-shadow: 0px 0px 2px 0px #4b4b4b;
    border: none;
}

.channel-operator-list__btn-add-operator:hover {
    transition: 0.5ms;
    background-color: rgba(150, 150, 150, 0.3);
}

.channel-operator-list__btn-add-operator:focus {
    background: none;
    box-shadow: none;
}

.channel-operator-list__operator {
    margin: 0 0.4rem;
    width: 10rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
}