.reports-container {
  height: calc(100% - 8rem);
}

.report-save-button-container {
  display: flex;
  justify-content: flex-end;
  margin-right: 5rem;
}

.report-table {
  margin: 1rem;
  overflow: auto;
  height: calc(100% - 9rem - 1px);
}

.report-table thead th {
  position: sticky;
  top: 0;
  z-index: 1;
  padding: 2px;
  background-color: white;
  box-shadow: inset 0px 0px 1px 0px black;
}

.report-table thead th .header-item {
  margin: 0.5rem 1rem;
}

.report-table thead th::first-letter {
  text-transform: uppercase;
}

.report-table td {
  vertical-align: top;
  max-width: 40rem;
  word-wrap: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-height: 10rem;
  position: relative;
}
