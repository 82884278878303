.operator-text-input__table .message-editor__container {
    position: absolute;
    bottom: 0rem;
    right: 0rem;
    display: flex;
    width: 100%;
    flex-direction: column;
    background-color: #f5f5f5;
}

.operator-text-input__table .message-to-edit {
    display: flex;
    flex-direction: column;
    margin: 0 0.5rem;
}

.operator-text-input__table .message-to-edit__header {
    text-align: left;
    font-weight: 500;
    display: flex;
}

.operator-text-input__table .message-to-edit__text {
    text-align: left;
    text-overflow: ellipsis;
    white-space: pre-line;
    overflow-y: auto;
    margin-bottom: .5rem;
}

.message-to-edit__footer {
    border-bottom: 1px solid black;
    margin-bottom: 0.5em;
}

.operator-text-input__table .message-editor {
    display: flex;
    flex-direction: row;
    margin: 0 0.5rem;
}

.operator-text-input__table .message-to-edit__close-editor {
    margin-left: auto;
    cursor: pointer;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
}

.operator-text-input__table .message-to-edit__close-editor:hover {
    color: #7f8c8d;
}

.message-editor .message-editor__remove-message {
    background-color: #d22e37;
    margin-left: 0.2rem;
}

.message-editor .message-editor__input {
    overflow: hidden;
    resize: none;
    width: 100%;
    border: none;
    border-bottom: #9e9e9e solid 1px;
    background-color: transparent;
    border-radius: 0;
    outline: none;
    font-size: 1rem;
    margin: 0 0 0.4rem 0;
    padding: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
}

.message-editor__input:hover,
.message-editor__input:focus {
    transition: none;
    box-shadow: none;
}