.contact-accounts__container {
    margin: 3.6rem 0;
}

.contact-accounts__header {
    display: flex;
    flex-direction: row;
}

.contact-accounts__header-title {
    width: 18rem;
    padding: 0.5rem 3.6rem;
    border-top-left-radius: 1.8rem;
    border-top-right-radius: 1.8rem;
    background: #26a69a;
    color: #fff;
}

.contact-accounts__table {
    border: 1px solid #ccc;
    padding: 0.5rem 3.6rem;
}

.contact-accounts__btn-header {}

.contact-accounts__table tr {
    border: none;
}

.contact-accounts__table td:first-child {
    width: 20%;
}

.contact-accounts__table td {
    max-width: 18rem;
    min-width: 8rem;
    width: 30%;
    padding: 1rem 0.9rem;
    text-align: left;
    white-space: nowrap;
}

.contact-account__btn {
    margin: 0 0.7rem;
    font-size: 1.3rem;
    padding: 0 auto;
}

.contact-accounts__no-records {
    margin: 0 7.2rem;
}