.chat-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: #f5f5f5;
    padding: 0 1rem;
}

.chat-container__message-item-container {
    width: 90%;
    max-width: 90%;
    display: inline-block;
    position: relative;
}

.chat-container__message-item-container * {
    font-family: Gilroy !important;
    font-weight: 500;
}

/*discard font rule for all tags inside*/
.wmde-markdown * {
    font-weight: revert;
}

.chat {
    width: 98%;
    height: 100%;
    background: white;
    overflow: auto;
}

.silent-chat-wrap {
    width: 2.7rem;
    height: 2.7rem;
    cursor: pointer;
}

.chat-container__opertator_text-input-container {
    display: block;
    width: 100%;
}

.chat-container__opertator_text-input-container td{
    text-align: center;
}

.operator-text-input__table {
    /* margin-top: 1rem; */
}

.operator-text-input_line td {
    vertical-align: bottom;
}

.operator-text-input_line {
    display: table-row;
    vertical-align: inherit;
}

.operator-text-input__table tr {
    border-bottom: 0rem;
}

.operator-text-input__table td {
    padding: 0;
    margin: 0;
}

.chat-container__opertator_menu {
    max-height: 10rem;
    word-break: break-all;
    word-break: break-word;
    border: 1rem solid transparent;
}

.chat-container__opertator_menu td{
    text-align: center;
    padding: 0rem;
    max-width: 25rem;
}

.chat-container__opertator_menu tr{
    border-bottom: 0rem;
}

.contact-avatar-container {
    /* display: flex; */
}

.contact-item__contact-avatar {
    display: inline-block !important;
    width: 3.6rem;
    height: 3.6rem;
}

.contact-item__contact-avatar.offline {
    border: 2px solid #f00;
}

.contact-item__contact-avatar.online {
    border: 2px solid #0f0;
}

.contact-name-container {
    width: 30rem;
}

.close-chat-panel-button{
    right: 4rem;
    top: 1.5rem;
}

.hide-chat-panel-button{
    display: inline-block;  
    position: absolute;
    margin-top: 0.8em;
}

.chat-header-channel-name {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.hide-chat-panel-button img {
    content: url("../shared/images/Chat/Close_chatPanel_button.svg");
    width: 3.6rem;
    border-radius: 50%;
    padding: 6px;
    margin-left: -0rem;
    cursor: pointer;
}

.hide-chat-panel-button img:hover {
    padding: 6px;
    border-radius: 50%;
    box-shadow: 0px 0px 2px 0px #000;
}

.contact-avatar-link {
    display: flex;
    justify-content: center;
    align-items: center;
}

.contact-avatar {
    border-radius: 50%;
    width: 4.8rem !important;
    height: 4.8rem !important;
    margin-left: -2.1rem;
    border: 12px solid green;
}

.contact-avatar svg {
    width: 100%;
    height: 100%;
}

.chat-name-container {
    white-space: nowrap;
}

.chat-container__file_loader {
    position: absolute;
    bottom: 12.6rem;
    left: 0;
    right: 0;
    font-size: small;
    font-weight: bold;
    opacity: 0.7;
}

.operator-text-input {
    overflow: hidden;
    resize: none;
    width: 100%;
    border: none;
    border-bottom: #9e9e9e solid 1px;
    background-color: transparent;
    border-radius: 0;
    outline: none;
    font-size: 1.8rem !important;
    margin: 0rem 0 0rem 0;
    padding: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
}

.client-tags-container {
    width: 30rem;
}

.operator-text-input__table textarea,
.operator-text-input__table textarea:hover,
.operator-text-input__table textarea:focus {
    font-size: initial;
    border: none !important;
    box-shadow: none !important;
    overflow-y: auto;
}

.chat-container__footer{
    position: relative;
}

.chat-footer__close-chat {
    background: none; 
    border: none; 
    box-shadow: none;
    padding: 0;
    margin: 0;
    text-transform: none;
    color: #039be5;
    font-size: 1.8rem;
    line-height: 1.8rem;
}


.chat-footer {
    /* position: fixed; */
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    font-size: 1.8rem;
    column-gap: 1rem;
    margin: 1rem 2rem;
}

.chat-footer tr {
    border: none;
}

.chat-footer button {
    cursor: pointer;
}

.chat-footer button:focus {
    background: none;
}

.search-answer-template-button {
    position: relative;
    right: -5px;
    bottom: -8px;
    opacity: 0.7;
    font-size: 2.9rem;
    cursor: pointer;
}

.search-answer-template-button:hover {
    opacity: 1;
}

.mic-button-recording {
    color: red;
    position:relative;
    right: 5px;
    bottom: -3px;
    opacity: 0.7;
    font-size: 22.5px;
    transition: opacity 1000ms ease-in-out;
}

.mic-button-recording:hover {
    cursor:pointer;
    opacity: 1;
}

.mic-button {
    position: relative;
    right: 5px;
    bottom: -5px;
    opacity: 0.7;
    font-size: 2.2rem;
}

.mic-button:hover{
    cursor:pointer;
    opacity: 1;
}

.send-button {
    content: url("../shared/images/Chat/send_button.svg");
    width: 3rem;
    vertical-align: middle;
    cursor: pointer;
    opacity: 0.7;
}

.send-button:hover {
    opacity: 1;
}

.chat__scroll-to-up-container {
    width: 3.6rem;
    height: 3.6rem;
    position: absolute;
    top: 18rem;
    right: 4.2rem;
    opacity: .3;
    background-color: rgb(215, 215, 215);
    border-radius: 50%;
}

.chat__scroll-to-bottom-container {
    width: 3.6rem;
    height: 3.6rem;
    position: absolute;
    bottom: 18rem;
    right: 4.2rem;
    opacity: .3;
    background-color: rgb(215, 215, 215);
    border-radius: 50%;
}

.chat__scroll-to-up {
    position: relative;
    width: 70%;
    height: 80%;
    background-image: url("../shared/images/Chat/ScrollDown.svg");
    background-repeat: no-repeat;
    background-position: center center;
    margin-top: 15%;
    margin-left: 15%;
    border-radius: 50%;
    transform: rotate(180deg)
}
 
.chat__scroll-to-bottom {
    position: relative;
    width: 70%;
    height: 80%;
    background-image: url("../shared/images/Chat/ScrollDown.svg");
    background-repeat: no-repeat;
    background-position: center center;
    margin-left: 15%;
    margin-top: 15%;
    border-radius: 50%;
}


.chat__scroll-to-bottom-container:hover {
    opacity: 0.9;
    cursor: pointer;
}

.chat__scroll-to-up-container:hover {
    opacity: 0.9;
    cursor: pointer;
}

.transfer-chat__open-operators {
    background: none;
    border: none;
    box-shadow: none;
    color: blue;
    text-transform: none;
    font-size: 1.8rem;
    text-align: center;
    width: 100%;
    padding: 0;
    float: left;
}

.transfer-chat__open-operators:hover {
    background: none;
    border: none;
    box-shadow: none;
}

.transfer-chat__open-operators:focus {
    background: none;
    border: none;
    box-shadow: none;
}

.chat-footer__transfer-chat {
    width: 100%;
}

.message-params {
    width: 3vh;
    padding-bottom: 0.5rem !important;
    padding-right: 0.5rem !important;
}

.chat-footer td {}

.invite-operator {
    float: left;
}

.transfer-chat__dropdown-operators {
    position: absolute !important;
    will-change: transform !important;
    top: 10rem !important;
    left: 0px !important;
    transform: translate3d(116px, -286px, 0px) !important;
}

.transfer-chat__closed {
    display: none;
}

.transfer-chat__dropdown-header {
    display: flex;
    flex-direction: column;
    height: 5.4rem;
    margin: 0 0 1.8rem 0;
}

.transfer-chat__dropdown-operators .transfer-chat__dropdown-item:nth-of-type(odd) {
    background-color: rgb(255, 255, 255, 1);
}

.transfer-chat__dropdown-operators .transfer-chat__dropdown-item:nth-of-type(even) {
    background-color: rgba(200, 200, 200, 1);
}

.transfer-chat__transfer-btn {
    width: 7.2rem;
    height: 2.7rem;
    padding: 0;
    font-size: 1.8rem;
    box-shadow: none;
    border-radius: 0;
    margin: auto auto 1rem auto;
    line-height: 0;
}

.transfer-chat__dropdown-operators .dropdown-item {
    border: none;
    height: 6.3rem;
}

.transfer-chat__dropdown-operators .dropdown-item:focus {
    background-color: rgb(239, 239, 239) !important;
}

.transfer-chat__dropdown-operators .dropdown-item:hover {
    background-color: rgb(150, 150, 150) !important;
}

.transfer-chat__no-online-operators {
    color: black;
    text-align: center;
    height: 5.4rem;
}

.transfer-chat__search {
    margin: 0 0.4rem;
    margin-top: 0.2rem;
}

.transfer-chat__col {
    padding-left: 0;
    padding: 0;
}

.transfer-chat__dropdown-operators hr {
    width: 90%;
}

.transfer-chat__footer {
    display: flex;
    flex-direction: column;
    margin-top: auto;
}

.chat__export-chat-btn {
    background: none; 
    border: none; 
    box-shadow: none;
    padding: 0;
    margin: 0;
    text-transform: none;
    color: #039be5;
    font-size: 1.8rem;
    line-height: 1.8rem;
}

.chat__export-chat-btn:focus {
    background: #bbbbbb;
    box-shadow: none;
    border: none;
}

.chat-container__scrollable i.material-icons {
    margin: auto 0;
}

.chat-container__scrollable {
    display: flex;
    flex-direction: row;
    height: 100%;
    overflow: hidden;
}

.chat-container__arrow-btn {
    cursor: pointer;
    color: #d5d5d5;
}

.chat-container__arrow-btn:hover {
    color: #222222;
}

.chat-container__message-preview {
    text-align: left;
    border: dashed greenyellow;
    display: flex;
    flex-direction: row;
}

.message-preview__text {
    width: 80%;
}

.typing-indicator {
    background-color: #E6E7ED;
    display: flex;
    height: fit-content;
    width: auto;
    border-radius: 50px;
    padding: 5px;
    margin-left: 10px;
    margin-top: 5px;
    margin-right: 5px;
    position: relative;
    -webkit-animation: 2s bulge infinite ease-out;
            animation: 2s bulge infinite ease-out;
  }
  .typing-indicator:before, .typing-indicator:after {
    content: '';
    position: absolute;
    bottom: -2px;
    left: -2px;
    height: 5px;
    width: 5px;
    border-radius: 50%;
    background-color: #E6E7ED;
  }
  .typing-indicator:after {
    height: 5px;
    width: 5px;
    left: -5px;
    bottom: -5px;
  }
  .typing-indicator span {
    height: 5px;
    width: 5px;
    float: left;
    margin: 0 1px;
    background-color: #9E9EA1;
    display: block;
    border-radius: 50%;
    opacity: 0.4;
  }
  .typing-indicator span:nth-of-type(1) {
    -webkit-animation: 1s blink infinite 0.3333s;
            animation: 1s blink infinite 0.3333s;
  }
  .typing-indicator span:nth-of-type(2) {
    -webkit-animation: 1s blink infinite 0.6666s;
            animation: 1s blink infinite 0.6666s;
  }
  .typing-indicator span:nth-of-type(3) {
    -webkit-animation: 1s blink infinite 0.9999s;
            animation: 1s blink infinite 0.9999s;
  }
  
  @-webkit-keyframes blink {
    50% {
      opacity: 1;
    }
  }
  
  @keyframes blink {
    50% {
      opacity: 1;
    } 
  }
  @-webkit-keyframes bulge {
    50% {
      -webkit-transform: scale(1.05);
              transform: scale(1.05);
    }
  }
  @keyframes bulge {
    50% {
      -webkit-transform: scale(1.05);
              transform: scale(1.05);
    }
  }