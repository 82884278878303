.contact-item__contact-info-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: left;
}

.contact-item__contact-avatar {
    border-radius: 50%;
    margin-right: 1.5rem;
    margin-left: 1.5rem;
    box-shadow: 0px 0px 2px 0px #fff;
    width: 5rem !important;
    height: 5rem !important;
}

.contact-item__contact-avatar svg {
    width: 100% !important;
    height: 100% !important;
}

.contact-item__contact-info {
    display: flex;
    flex-direction: column;
}

.contact-item__table-row {
    transition: 0.4s;
    cursor: pointer;
}

.contact-item__table-row:hover {
    transition: 0.1s;
    box-shadow: 0px 0px 8px 0px #26a69a;
}

.contact-item__contact-info-cell {
    width: 30rem;
    min-width: 15rem;
    overflow: hidden;
    text-overflow: ellipsis;
}

.contact-item__messengers {
    max-width: 15rem;
}

.contact-item__creation-date {
    width: 15rem;
    min-width: 10rem;
    overflow: hidden;
    text-overflow: ellipsis;
}

.contact-item__chats-count {
    width: 10rem;
    min-width: 10rem;
    overflow: hidden;
    text-overflow: ellipsis;
}
