.topic-list__button {
}

.topic-list__button-cancel-create {
  background-color: #d22e37;
}

.topic-list__container {
  height: calc(100vh - 6rem);
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.topic-list__header-buttons {
  text-align: left;
  margin: 1.5rem 4rem;
}

.topic-list__items {
  min-width: 60rem;
  margin: 0 1rem;
}

.w-md-editor-fullscreen {
  right: 8rem;
  left: 10rem;
}

.topics-list__id {
    cursor: pointer;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Chrome/Safari/Opera */
    -khtml-user-select: none; /* Konqueror */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently not supported by any browser */
    text-align: left;
}

.topic-table {
    margin-left: 20px;
    font-weight: bold;
}

.topic-table th{
    padding-right: 50px;
}