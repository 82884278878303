.scalable-text-input {
    overflow: hidden;
    resize: none;
    width: 100%;
    border: none;
    border-bottom: #9e9e9e solid 1px;
    background-color: transparent;
    border-radius: 0;
    outline: none;
    font-size: 1.8rem;
    margin: 0 0 0.7rem 0;
    padding: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    -webkit-transition: border 0.3s, -webkit-box-shadow 0.3s;
    transition: border 0.3s, -webkit-box-shadow 0.3s;
    transition: box-shadow 0.3s, border 0.3s;
    transition: box-shadow 0.3s, border 0.3s, -webkit-box-shadow 0.3s;
}

.scalable-text-input:focus {
    outline-offset: -2px;
}

textarea:focus:not([readonly]) {
    border-bottom: 1px solid #26a69a;
    -webkit-box-shadow: 0 1px 0 0 #26a69a;
    box-shadow: 0 1px 0 0 #26a69a;
}
