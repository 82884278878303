.topic-item {
    display: flex;
    justify-content: space-between;
    position: relative;
    margin-top: 1rem;
    margin-bottom: 1rem;
    line-height: 3.5rem;
    background-color: #fff;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
    border-radius: 2px;
    padding: 1rem 2rem;
}

.topic-item .topic-item__content {
    display: flex;
    cursor: pointer;
}

.topic-item .topic-item__content-grow {
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    width: min-content;
}

.topic-item .topic-item__identifier {
    color: #cccccc;
    font-size: 1.6rem;
}

.topic-item .topic-item__name {
    padding-left: 1rem;
    margin-right: auto;
    word-break: break-word;
}

.topic-item .topic-item__button {
    margin-left: 0.4rem;
    margin-right: 0.4rem;
}

.topic-item .topic-item__button-edit {
    background-color: #1696a6;
}

.topic-item .topic-item__button-delete {
    background-color: #d22e37;
}

.topic-item .topic-item__button-save {
    background-color: #d6ac17;
}

.topic-item .topic-item__button-discard {
    background-color: #d22e37;
}
