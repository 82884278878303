.login-form {
    width: 30%;
    margin: auto;
    min-width: 40rem;
}

.login-form .login-form__body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 4rem;
}

.login-form .login-form__body .login-form__item {
    width: 100%;
    display: flex;
    align-items: center;
    position: relative;
    margin-bottom: 2rem;
}

.login-form .login-form__body .login-form__item--center {
    justify-content: center;
    margin-bottom: 0;
}

.login-form .login-form__body .login-form__item .login-form__field-description {
    padding-right: 2rem;
    width: 20%;
    max-width: 20%;
}

.login-form .login-form__body .login-form__item .login-form__field-input {
    flex: 0 0 80%;
    max-width: 75%;
}

.password-checkbox{
    position: absolute;
    right: 0;
}

.password-checkbox img {
    width: 1rem;
    content: url("./../../../shared/images/opened_eye.svg");
}

.password-checkbox-checked img{
    width: 1rem;
    content: url("./../../../shared/images/closed_eye.svg") !important;
}
