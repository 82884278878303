.chat-item__row {
    cursor: pointer;
}

.chat-item__row tr {
    border-bottom: 0px;
}

.chat-item__row td {
    padding: 0px;
    margin-right: 1rem;
}

.chat-item__chat-id {
    font-style: italic;
    font-size: small;
    color: #ccc;
    float: left;
}

.chat-item__chat-info {
    text-align: right;
    padding-right: 2rem !important;
}

.chat-item__contact-info_row {
    padding: 0px;
}

.chat-item__contact-message-column {
    min-width: 30rem;
    width: 25px;
    margin-right: 1rem;
}

.chat-item__contact-info_avatar {
    width: 5rem;
}

.chat-item__operator-info_avatar {
    width: 5rem;
    padding-right: 2rem !important;
}

.chat-item__contact-info_chat-info {
    text-align: left;
    width: 70%;
}

.chat-item__contact-info_name-editor {
    display: flex;
    flex-direction: row;
    font-size: 1.8rem !important;
    height: 1.8rem !important;
}

.chat-item__contact-info_name-editor-input,
.chat-item__contact-info_name-editor-input:hover,
.chat-item__contact-info_name-editor-input:focus {
    border: none !important;
    height: 2.1rem !important;
    width: auto !important;
    padding: 0 !important;
    margin: 0 !important;
    line-height: 0 !important;
    padding-left: .5rem !important;
    margin-right: .5rem !important;
    font-size: 1.8rem !important;
}

.chat-item__contact-info_change-name-btn,
.chat-item__contact-info_change-name-btn:focus {
    height: 2.1rem;
    width: auto;
    line-height: 0;
    padding: 0.4rem;
    margin: 0;
    border: none;
    box-shadow: none;
    color: '#fff';
}

.chat-item__contact-info_change-name-btn:hover {
    background: #AAAAAA;
    color: black;
}

.chat-item__contact-info_name {
    text-align: left;
    font-weight: bold;
    font-size: 1.8rem;
    font-weight: 500;
}

.chat-item__contact-message-column{
    max-width: 23rem;
}

.chat-item__contact-info_message {
    margin-top: .3rem;
    font-weight: normal;
    text-align: left;
    overflow: hidden;
}

.line-clamp {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.chat-item__contact-avatar {
    width: 3rem !important;
    height: 3rem !important;
    border-radius: 50%;
    vertical-align: inherit;
    box-shadow: 0px 0px 2px 1px #ccc;
    object-fit: cover;
}

.chat-item__contact-avatar svg {
    width: 100% !important;
    height: 100% !important;
}

.chat-item__operator-avatar {
    width: 5rem !important;
    height: 5rem !important;
    border-radius: 50%;
    box-shadow: 0px 0px 2px 1px #ccc;
    margin-left: 1rem;
}

.chat-item__operator-avatar svg {
    width: 100% !important;
    height: 100% !important;
}

.chat-item__contact-info-cell {
    text-align: center;
    padding: 1rem 0 1rem 1rem !important;
}

.chat-item__messenger-status-cell {
    min-width: 10rem;
}

.chat-item__messenger-info-cell {
    max-width: 15rem;
    padding: 1rem !important;
    padding-right: none !important;
}

.chat-item__messenger {
    display: flex;
    align-items: center;
    justify-content: center;
}

.chat-item__messenger-img {
    width: 1.8rem;
    height: 1.8rem;
    display: inline-block;
}

.chat-item__channel-name {
    display: inline-block;
    margin-left: .5rem;
    font-size: 1.8rem;
}

.chat-item__is-closed-text {
    font-size: 1.8rem;
    font-weight: 500;
}

.chat-item__operator-info {
    white-space: pre-line;
    display: block;
    font-size: 1.6rem;
    font-weight: 500;
    min-width: 5rem;
    overflow: hidden;
    float: right;
}

.chat-item__operator-info-cell {
    width: 20rem;
    text-align: center;
}

.chat-item__row:hover {
    transition: 0.1s;
    box-shadow: 0px 0px 8px 1px #26a69a !important;
}

.chat-item__row-selected {
    background: linear-gradient(to right, #26a69a 1rem, transparent 0px);
}

.chat-item__chat-paused {
    float: right;
    font-size: small;
    font-weight: bold;
    margin-right: 2rem;
    margin-top: -.3rem;
    padding:  0rem 1rem;
    border: 2px solid orange;
}

.chat-item__chat-created-time {
    font-style: italic;
    font-size: small;
    float: right;
}

.chat-item-with-message-from-operator {
    /* font-weight: 400; */
}

.chat-item-without-message-from-operator {
    font-weight: bold;
}

.chat-item__unread-messages-count {
    float: right;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 1.1rem;
    font-family: cursive;
    text-align: center;
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    margin-left: 1rem;
    margin-right: -1rem;
}

.unread-messages-count-closed-chat {
    /* background-color: #aaa; */
    box-shadow: 0px 0px 1px 1px black;
    color: black;
}

.unread-messages-count-my-chat {
    background-color: #d22e37;
}

.unread-messages-count-withme-chat {
    background-color: #d6ac17;
}

.unread-messages-count-withoutme-chat {
    background-color: #26a69a;
}

.chat-item-with-message-from-operator-message-time {
    font-style: italic;
    font-size: small;
}