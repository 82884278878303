.analytics-container {
    height: calc(100vh - 6rem);
    overflow: auto;
    position: relative;
}

.analytics-list__table {
    /* height: calc(100vh - 13rem);
    overflow: auto; */
}

.analytics-tabs-wrapper {
    width: fit-content;
    white-space: nowrap;
    float: left;
    border-bottom: 1px solid rgb(161, 161, 161);
    min-height: 4rem;
    line-height: 4rem;
}

.analytics-tabs-wrapper span{
    margin-left: 1.6rem;
    margin-right: 1.6rem;
    font-family: Arial, Helvetica, sans-serif;
    font-size: medium;
    cursor: pointer;
    padding-bottom: 0.9rem;
    -moz-user-select: none;
    -khtml-user-select: none;
    user-select: none;
    margin-top: auto;
}

.analytics-tabs-wrapper span.active{
    font-weight: bold;
    border-bottom: 2px solid #26a69a;
}

.analytics-header {
    display: flex;
    flex-direction: row;
    margin: 1.5rem 4rem;
    height: 4rem;
    line-height: 4rem;
}

.analytics-subtab-header {
    flex-direction: column;
}

.analytics-tabs__searching-input-container {
    width: 65%;
    margin-left: 6rem;
}