.license-expires-dialog-container {
    margin-left: 5px;
    margin-right: 5px;
    width: 99%;
    align-self: center;
    display: flex;
    justify-content: center;
    background-color: #f4dd48;
    align-items: center;
    margin-top: 14px;
    border-radius: 2px;
    border: 2px #666666 solid;
    height: 30px;
    opacity: 0.7;
    cursor: pointer;
}

.close-dialog-button {
    position: absolute;
    height: 26px;
    right: 12px;
}


.close-dialog-button img {
    content: url("../../shared/images/Chat/Close_chatPanel_button.svg");
    width: 2.6rem;
    border-radius: 50%;
    padding: 6px;
    margin-left: -0rem;
    cursor: pointer;
}

.close-dialog-button img:hover {
    padding: 6px;
    border-radius: 50%;
    box-shadow: 0px 0px 2px 0px #000;
}



.license-expires-dialog-container__text {
    margin-left: 10px;
    margin-bottom: 3px;
    margin-right: 20px;
    font-size: 18px;
}

