.chats-list-container {
    display: flex;
    flex-direction: column;
}

.chats-list-container td,
.chats-list-container th {
    text-align: center;
}

.chats-list__header {
    margin: 1.5rem 4rem;
    display: flex;
    align-items: flex-end;
    height: 4rem;
    line-height: 4rem;
}

.chats-list__search-mode {
    display: flex;
    flex-wrap: nowrap;
    margin-right: 3rem;
    border-bottom: 1px solid rgb(161, 161, 161);
}

.chats-list__search-mode span{
    margin-left: .5rem;
    margin-right: .5rem;
    font-family: Arial, Helvetica, sans-serif;
    font-size: medium;
    cursor: pointer;
    /* padding-bottom: .2rem; */
    margin-bottom: -2px;
    -moz-user-select: none;
    -khtml-user-select: none;
    user-select: none;
    white-space: nowrap;
}

.chats-list__search-mode span.active{
    font-weight: bold;
    border-bottom: 2px solid #26a69a;
}

.chats-list__search {
    float: right;
    width: 30%;
}

.chats-list__search-mode .active {
    opacity: 1;
}

.chats-list__search-mode__all-chats {
    position: absolute;
    margin-top: -1.5rem;
    margin-left: -3rem;
    opacity: .5;
}

.chats-list__search-mode__my-chats {
    position: absolute;
    margin-top: -1.5rem;
    margin-left: -1rem;
    opacity: .5;
}

.chats-list__btn-more {
    width: 4rem;
    padding: 0;
    margin-left: auto;
    margin-right: auto;
    margin-top: 1rem;
}

.chat-item__chat-id-cell {
    width: 1rem;
    text-align: left;
}

.chat-item__channel-info-cell {
    margin-left: 10rem;
}

.chats-list__body {
    overflow: auto;
    height: calc(100vh - 17rem);
}

.chats-list__body.splittered {
    left: 1rem;
    right: 0rem;
}

.auto-update-chats-container {
    font-size: large;
    width: fit-content;
    margin-left: 3rem;
    height: 4rem;
    overflow: hidden;
}

.auto-update-chats-check svg{
    font-size: large;
}

