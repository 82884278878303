.contact-account-item__btn {
    margin: 0 0.4rem;
    font-size: 0.7rem;
    padding: 0 auto;
}

.contact-account-item__type-input {
    font-size: 1.8rem;
    display: inline-block;
    border: none;
    padding: 0 !important;
    width: 10rem;
}

.contact-account-item__id-input {
    margin: 0 !important;
}

.contact-account-item__login-input {
    margin: 0 !important;
}

.contact-account-item__type {
    font-size: 1.8rem;
    width: 6rem;
    font-weight: 700;
    margin-top: 0.78rem;
    margin-bottom: 0.72rem;
}

.contact-account-item__id {
    display: block;
    font-weight: 700;
    margin-top: 0.78rem;
    margin-bottom: 0.72rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.contact-account-item__login {
    font-weight: 700;
    margin-top: 0.78rem;
    margin-bottom: 0.72rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.contact-account-item__btn-edit {
    background-color: #1696a6;
}

.contact-account-item__btn-delete {
    background-color: #d22e37;
}

.contact-account-item__btn-save {
    background-color: #d6ac17;
}

.contact-account-item__btn-replay {
    background-color: #d22e37;
}
