.transfer-chat__operator-item {
    margin-bottom: 1.5rem;
    display: flex;
    flex-direction: row;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none;   /* Chrome/Safari/Opera */
    -khtml-user-select: none;    /* Konqueror */
    -moz-user-select: none;      /* Firefox */
    -ms-user-select: none;       /* Internet Explorer/Edge */
    user-select: none;
    cursor: pointer;
}

.operator-item__operator-info p {
    margin: 0;
    color: black;
    line-height: 1.1;
    text-align: left;
}

.transfer-chat__operator-item .operator-item__operator-info {
    margin-left: .5rem;
}

.operator-item__operator-info .operator-item__tier1 {
    color: darkcyan !important;
}

.operator-item__operator-info .operator-item__tier2 {
    color: darkgreen !important;
}

.operator-item__operator-info .operator-item__tier3 {
    color: darkblue !important;
}

.operator-item__operator-info .operator-item__tier-supervisor {
    color: darkred;
}

.transfer-chat__operator-item .operator-item__operator-avatar {
    width: 7.3rem !important;
    height: 7.3rem !important;
    border-radius: 50%;
    border: 1px solid;
    float: left;
    margin: auto 0;
}

.transfer-chat__operator-item .operator-item__operator-avatar.offline {
    border: 1px solid red;
}

.transfer-chat__operator-item .operator-item__operator-avatar.online {
    border: 1px solid #0F0;
}

.transfer-chat__operator-item .operator-item__operator-avatar.pause {
    border: 1px solid #DD0;
}

.operator-item__selected-icon {
    margin: auto 0;
    margin-left: auto;
}

.transfer-chat__operator-item .operator-item__status-online {
    color: #00FF0080 !important;
}

.transfer-chat__operator-item .operator-item__status-pause {
    color: #FFA500 !important;
}

.transfer-chat__operator-item--avatar-container {
    display: flex;
    flex-direction: column;
}