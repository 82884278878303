.login-container {
    display: flex;
    justify-self: center;
    align-items: center;
    flex-direction: column;
    margin-top: 12rem;
}

.login-build-version {
    color: #999999;
    margin-top: 1rem;
}
