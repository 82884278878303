.topic-pairs-tabs__header {
  display: flex;
  margin: 1.5rem 4rem;
  height: 4rem;
  max-height: 4rem;
}

.topic-pairs-tabs__actions {
  border-bottom: 1px solid rgb(161, 161, 161);
  margin-right: 3rem;
  height: 4rem;
  line-height: 4rem;
}

.topic-pairs-tabs__filter {
  width: 100%;
  position: relative;
}

.topic-pairs-tabs__tab-button {
  box-shadow: none;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  border-top-left-radius: 2px;
  background-color: transparent;
  color: #000000;
  margin: 1rem;
  cursor: pointer;
  margin-bottom: -2px;
}

.topic-pairs-tabs__tab-button.active {
    border-bottom: 2px solid #26a69a;
    padding-bottom: 0.9rem;
    font-weight: bold;
}

.topic-pairs-tabs__tab-button.disabled {
  cursor: not-allowed;
}

.topic-pairs-tabs__button-create {
  background-color: #26a69a;
  margin-left: 2.7rem;
}

.topic-pairs-tabs__button-cancel-create {
  background-color: #d22e37;
}

.topic-pairs-tabs__buttons-header {
  display: flex;
}

.topic-pairs-tabs__topic-pair-items {
  height: calc(100vh - 15rem);
  overflow: auto;
  padding: 0 1rem;
}

.topic-pairs-tabs__paging {
  padding: 0 1rem;
}
