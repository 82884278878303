.invited-operator-item {
    margin-bottom: 1.5rem;
    display: flex;
    flex-direction: row;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none;   /* Chrome/Safari/Opera */
    -khtml-user-select: none;    /* Konqueror */
    -moz-user-select: none;      /* Firefox */
    -ms-user-select: none;       /* Internet Explorer/Edge */
    user-select: none;
    cursor: pointer;
}

.invited-operator-item__avatar {
    width: 7.3rem !important;
    height:  7.3rem !important;
    border-radius: 50%;
    border: 1px solid;
    float: left;
    margin: auto 0;
}

.invited-operator-item__avatar.offline {
    border: 1px solid red;
}

.invited-operator-item__avatar.online {
    border: 1px solid #0F0;
}

.invited-operator-item__avatar.pause {
    border: 1px solid #DD0;
}

.invited-operator-item__selected-item {
    margin: auto 0;
    margin-left: auto;
}

.invited-operator-item__info {
    margin-left: .5rem;
    max-width: 100%;
    overflow: hidden;
}

.invited-operator-item__info p {
    margin: 0;
    line-height: 1.1;
    text-align: left;
}

.invited-operator-item__info .invited-operator-item__tier1 {
    color: darkcyan !important;
}

.invited-operator-item__info .invited-operator-item__tier2 {
    color: darkgreen !important;
}

.invited-operator-item__info .invited-operator-item__tier3 {
    color: darkblue !important;
}

.invited-operator-item__info .invited-operator-item__tier-supervisor {
    color: darkred !important;
}

.invited-operator-item__info .invited-operator-item__status-online{
    color: #00FF0080 !important;
}

.invited-operator-item__info .invited-operator-item__status-pause {
    color: #FFA500 !important;
}

.invited-operator-item__status-container {
    display: flex;
    flex-direction: column;
}