.customer-journey-table th, .customer-journey-table td{
    text-align: unset;
}

.bot-switch-wrapper {
    margin: 1.5rem 4rem;
}

.bot-switch-select {
    display: block;
    width: auto;
}

.chat-step-line__subline {
    border: 1px solid;
    border-radius: 5px;
    padding: 5px;
    margin: 10px;
    background-color: #f5f5f5;
    cursor: pointer;
}

.chat-step-line__procentage {
    font-weight: bold;
}

.chat-step-line__key{ 
    margin-bottom: 1rem;
}

.chat-step-line__key-button {
    border: 1px solid;
    border-radius: 10px;
    margin: .4rem;
}

@keyframes highlight {
    0% {
        background: red
    }
    100% {
        background: none;
    }
}

.highlight {
   animation: highlight 1s;
}