.operator-item td {
    vertical-align: bottom;
}

.operator-item__operator-actions {
    display: flex;
    flex-direction: row;
    min-width: 18rem;
    column-gap: 0.5rem;
}

.operator-item__operator-avatar {
    width: 6.2rem !important;
    height: 6.2rem !important;
    border-radius: 50%;
    margin-top: -1.5rem;
    margin-left: auto;
    margin-right: auto;
    display: block;
    cursor: pointer;
}

.operator-item__operator-avatar svg {
    width: 100% !important;
    height: 100% !important;
}

.operator-item__operator-avatar.online {
    border:rgb(0, 255, 0) 3px solid;
}

.operator-item__operator-avatar.pause {
    border: rgb(221, 221, 0) 3px solid;
}

.operator-item__operator-avatar.offline {
    border: red 3px solid;
}

.operator-item__status {
    font-size: medium;
    text-align: center;
}

.operator-item__button-discard {
    background-color: #d22e37;
}

.operator-item__btn {}

.operator-item__btn-delete {
    background-color: #d22e37;
}

.opertor-item__operator-type {
    display: block;
    color: black;
    outline: none;
    padding: 0;
    margin: 0;
    border: none;
    border-bottom: 1px solid #9e9e9e;
    transition: box-shadow .3s, border .3s;
    margin-bottom: 2rem !important;
}

.opertor-item__operator-type:focus {
    display: block;
    border: none;
    outline: none;
    border-bottom: 1px solid #26a69a;
    box-shadow: 0 1px 0 0 #26a69a;
}

.operator-item__operator-name {
    margin-bottom: 2rem !important;
    padding: 0 !important;
}

.operator-item__label {
    position:absolute;
    margin-top: -2rem;
    white-space: nowrap;
}

.operator-item__input_cell {
    padding-left: 0.8rem;
}

.operator-item .is-invalid {
    border-bottom: 1px solid red !important;
}

.operator-item .is-invalid:focus{
    box-shadow: 0 1px 0 0 red !important;
}

.operator-item__input-file-button {
    display: none;
}

.operator-item__upload-script-label {
    background-color: #26a69a;
    color: white;
    letter-spacing: .5px;
    font-size: 1.2rem;
    border: none;
    border-radius: 2px;
    border-bottom-right-radius: 0;
    padding: 0.6rem 1.6rem;
    cursor: pointer;
    text-transform: uppercase;
    /* width: 10rem; */
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
}

.operator-item__upload-script-label:hover {
    background-color: #2bbbad;
}

.uploaded-script-name {
    display: inline-block !important;
    color: black;
    min-width: 0;
    background: none;
    padding: 0.3rem 0.3rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.operator-item__input_cell div {
    display: flex;
}

.operator-item__input {
    border-bottom: 1px solid #ddd;
    padding-bottom: 0.3rem;
    margin-bottom: 2rem !important;
}

.operator-item-avatar-status {
    min-width: 6.2rem;
    padding-left: 1rem;
}

.operator-item__name {
    min-width: 15rem;
}

.operator-item__type {
    min-width: 15rem;
}

.operator-item__login {
    min-width: 20rem;
    max-width: 0;
}

.operator-item__password {
    min-width: 15rem;
}

.operator-item__enabled {
    min-width: 8rem;
}

.operator-item__operator-login {
    padding: 0 !important;
    margin-bottom: 2rem !important;
}

.operator-item__operator-password {
    padding: 0 !important;
    margin-bottom: 2rem !important;
}

.operator-item__operator-is-enabled {
    margin-bottom: 3rem !important;
}