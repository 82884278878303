.button-link {
    border: none;
    background: transparent;
    cursor: pointer;
    text-align: left;
    color: #039be5;
    word-break: break-all;
}

.button-link:hover{
    text-decoration: underline;
}

button.button-link:focus {
    background: transparent;
}