.activation-container {
  width: 50%;
  min-width: 30rem;
  margin: auto;
  margin-top: 5rem;
  background-color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
  border-radius: 2px;
  padding: 3rem;
}

.activation-container h5 {
  margin: 1rem;
  font-weight: bold;
  font-size: 2.4rem;
}

.activation-container__form input[type="text"],
.activation-container__form input[type="password"] {
  width: 50%;
}

.activation-container__form textarea {
  width: 90%;
  height: 20rem;
}

.activation-container__form label {
  display: inline-block;
  width: 10rem;
  text-align: left;
  margin-right: 4rem;
}

.activation-container__form-item {
  display: flex;
  justify-content: center;
  align-items: center;
}

.activation-container__form-item-license {
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

input[type="password"]::-ms-reveal,
input[type="password"]::-ms-clear {
  display: none;
}

button[type="submit"] {
  margin-top: 2rem;
}
