.setting-body__line {
    height: 7.2rem;
    display: flex;
}

.setting-header__close-button-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
}

.setting-header__close-button-wrapper button {
    margin-right: 1.8rem;
}

.setting-body__setting-id-wrapper {
    display: block;
    width: 80%;
    max-width: 100rem;
    float: left;
}

.setting-body__setting-type-wrapper { 
    display: block; 
    float: right;
}

.setting-body__setting-type {
    display: block;
    width: auto;
}

.setting-body__key-value-pairs-list-wrapper {
    max-height: 36rem;
    overflow-y: auto;
}

.setting-body__add-button-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 1.8rem;
}

.setting-body__key-wrapper {
    float: left;
    width: 35%;
    margin-right: 1.8rem;
}

.setting-body__value-wrapper {
    float: left;
    width: 50%;
    margin-right: 1.8rem;
}

.setting-body__value{
    resize: none;
    height: 5.4rem;
}

.setting-body__delete-key-value-wrapper {
    padding-top: .8rem;
    margin-right: 1.8rem;
    height: 100%;
    float: right;
}

.setting-body__delete-key-value {
    background-color: #d22e35;
}

.setting-item-modal.modal-dialog {
    max-width: 65%;
    min-width: 65%;
    overflow: hidden;
}

.setting-item-modal .modal-title {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: bold;
    font-size: 3.6rem;
}

.setting-footer-wrapper {
    margin-right: 5.4rem;
}

.setting-body__textarea {
    height: 450px;
}

.setting-item-container {
    margin-left: 0.4rem;
    margin-right: 0.4rem;
}